@import '/src/theme/constants.scss';

.slider__freemode__child-wrapper, .swiper-slide {
  .camp-card {
    width: 100% !important;
    margin: 0 0 $jmt-spacing !important;
    height: 100% !important;
  }
}

.camp-card {
  width: 100%;
  text-align: left;
  height: auto !important;

  > * {
    height: 100% !important;
  }

  &__action {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: $jmt-spacing;
    height: auto !important;
  }

  @media (min-width: 630px) {
    width: calc(50% - 20px);
  }

  &--small {
    max-width: 210px;
    > ion-card {
      width: 100%;
    }

    > * {
      height: auto !important;
    }
  }

  img {
    max-width: 100%;
    width: 100% !important;
    //height: 120px !important;
    object-fit: cover;
  }

  ion-card {
    position: relative;
  }

  ion-card-title {
    padding: $jmt-spacing !important;
    hyphens: auto;
  }

  ion-card-content {
    padding: $jmt-spacing $jmt-spacing $jmt-spacing*5 $jmt-spacing !important;

    .jmt-button {
      width: calc(100% - $jmt-spacing*0.5);
      text-overflow: ellipsis;
    }
  }
}
