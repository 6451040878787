@import "src/theme/constants";
@import "src/theme/transitions";
@import "src/theme/typography";

.form-birthday-field {

    &__item:first-of-type {
        --padding-start: 0;
        --inner-padding-end: 0;
    }

    &__row {
        display: flex;

        .form-input:not(:last-child) {
            margin-right: $jmt-spacing;
        }
    }
}
