@import "src/theme/typography";
@import "src/theme/constants";

.context-menu-modal {
    --min-width: 250px;
    --height: auto;
    align-items: flex-end;
    --max-height: 80%;
    --border-radius: #{$jmt-spacing * 4} #{$jmt-spacing * 4} 0 0;
    --max-width: #{$jmt-max-context-menu-width};

    &.md {
        --border-radius: #{$jmt-spacing * 4} #{$jmt-spacing * 4} 0 0;
    }

    > div {

        ion-list {
            margin-top: $jmt-spacing;
            overflow-y: scroll;
            max-height: calc(80vh - 50px);

            ion-tiem {
                padding-top: 2*$jmt-spacing;
                padding-bottom:2*$jmt-spacing;
            }
        }

        > ion-item {
            margin-bottom: env(safe-area-inset-bottom);
            --border-radius: 0;
        }
    }
}

.context-menu-item {
    --padding-end: #{$jmt-spacing};
    --padding-start: #{4*$jmt-spacing}!important;
    --border-color: var(--ion-color-step-175);
    --padding-top: #{$jmt-spacing};
    --padding-bottom: #{$jmt-spacing};

    ion-label {
        display: flex !important;
        align-items: center;

        &.underlined {
            text-decoration: underline;
        }

        ion-icon {
            margin-right: 2*$jmt-spacing;
        }
    }
}
