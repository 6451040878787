@import "/src/theme/variables";
@import "/src/theme/_mixins";

.cert-page {

  .buttons__wrapper {
    > ion-button {

      &:nth-of-type(2) {
        &:before {
          background-color: rgba(0, 0, 0, 0.3) !important;
        }
      }
    }
  }

  &__intro {
    ion-col {
      position: relative;
      #certificate-page-1 {
        display: none;
        @include desktop {
          display: block;
          transform: scale(.4);
        }
        pointer-events: none;
        width: 928px;
        height: 640px;
        top: $jmt-spacing*2;
        left: $jmt-spacing*2;
        transform: scale(.4);
        position: absolute;
        transform-origin: top left;
      }

      #certificate-page-2 {
        display: none;
      }
    }

    &__arrow {
      max-height: 150px;
      @include desktop {
        margin-left: -80px;
      }
    }

    &__jmt {
      max-width: 300px;
      margin: 8px auto;
      @include desktop {
        margin-bottom: 64px;
      }
    }
  }

  .card {
    background-color: #ffffff;
    padding: $jmt-spacing*2;
  }

  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 0, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0f0f0f;
  --ion-color-secondary-tint: #373737;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-card-background: #ffffff;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  //--ion-text-color: #000000 !important;
  //--ion-text-color-rgb: 0,0,0 !important;
  //--ion-card-color: #000000;

  width: 100vw;
  min-width: 100vw;
  --ion-color-header: #fff;

  .header {
    max-width: 100vw;

    &__toolbar {
      //--ion-color-contrast: #000;
      //--color: black !important;
      //color: black !important;
      //@include desktop {
      //  --padding-start: calc(50vw - #{$jmt-width-desktop*0.5}) !important;
      //  --padding-end: calc(50vw - #{$jmt-width-desktop*0.5}) !important;
      //}
    }
  }

  ion-content {
    --ion-background-color: transparent;
  }
}

.camp {
  max-width: 1200px;
  margin: 0 auto;

  ion-header {
    max-width: 960px;
  }

  ion-content > *:not(.content__background) {
    max-width: 100%;
    width: 992px;
  }

  .certificates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px;
    background-color: #ffffff;
    @media (max-width: 992px) {
      flex-direction: column;
      align-content: center;
    }
  }
}

.certificate-page {

  .cert_page_full-width {
    width: 100%;
  }

  .spacing-4 {
    ion-col {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 4px;

      &:nth-of-type(2n) {
        padding-right: 0;
        padding-left: 4px;
      }
    }
  }

  .certificate-camp-card {
    .certificate-camp-card__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include desktop() {
      &:nth-of-type(2n) {
        .certificate-camp-card__wrapper {
          flex-direction: row-reverse !important;

          .certificate-camp-card__content {
            padding: $jmt-spacing;
          }

          .certificate-camp-card__img {
            width: 30%;
          }
        }
      }
      .certificate-camp-card__wrapper {
        flex-direction: row !important;
        width: 100%;
      }
    }

    &__img {
      @include desktop() {
        height: 100%;
        width: calc(40% - $jmt-spacing);
        > img {
          height: 100%;
          display: block;
        }
      }
    }

    &__content {
      padding: $jmt-spacing;

      @include desktop() {
        width: 60%;
        padding: $jmt-spacing 0;
      }
    }
  }

}
