@import "constants";

.cursor-pointer {
    cursor: pointer;
}

.color-jmt {
    color: var(--ion-color-jmt) !important;
}

hr {
    width: 100%;
    height: 2px;
    background-color: $jmt-color-stripe-light;
    margin: 0;
}

* {
    outline: none;
}

.display-flex {
    display: flex;
}

.display-flex-column {
    display: flex;
    flex-direction: column;
}

.color-gold {
    color: $jmt-color-gold;
}

.color-secondary {
    color: var(--ion-color-secondary);
}

.overflow-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

@-webkit-keyframes autofill {
    0%,100% {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 9999s;
}

@include desktop {
    .jmt-page {
        &:after {
            pointer-events: none;
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: $jmt-width-desktop;
            background-color: rgba(0, 0, 0, 0.02);
            @media (prefers-color-scheme: dark) {
                background-color: rgba(255, 255, 255, 0.02);
            }
        }

        &.camp-preview:after {
            width: 992px;
        }
    }
}

h1 {
    text-transform: uppercase;
}

ion-text {
    font-size: $jmt-fontsize-m;
    line-height: $jmt-lineheight-m;
}

body {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important;

    *::-webkit-scrollbar {
        display: none !important;
    }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
}

.n2br {
    white-space: pre-line;
}

.swiper image {
    display: block;
}

.section-description {
    padding: 0 $jmt-spacing $jmt-spacing;
    width: 100%;
}

a, a:visited {
    color: currentColor;
}

@font-face {
    font-family: Rubik;
    src: url( './../assets/fonts/Rubik-VariableFont_wght.ttf') format('truetype');
    font-weight: 1 999;
}

@font-face {
    font-family: Rubik;
    src: url("./../assets/fonts/Rubik-Italic-VariableFont_wght.ttf");
    font-style: italic;
    font-weight: 1 999;
}
