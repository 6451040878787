@import 'src/theme/constants';

.bmg-certificate-wrapper {
  h1, h2, h3 {
    font-family: Bungee;
  }

  .hide-small {
    @media (max-width: 992px) {
      display: none !important;
    }
  }

  .hide-big {
    @media (min-width: 993px) {
      display: none !important;
    }
  }

  position: relative;
  overflow: hidden;
  padding: 0;
  background: #231F20;
  color: white;
  @media(min-width: 993px) {
    background: url("../../../assets/bmg/cert_bg.png") no-repeat center center;
    background-size: cover;

    &#certificate-page-2 {
      background: #231F20;
    }

  }
  @media(min-width: 993px) {
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.5);
    height: 640px;
  }
  margin-bottom: $jmt-spacing * 2;

  @media (max-width: 992px) {
    width: 360px;
    margin: 0 auto;
  }

  .certificate {
    color: #DADADAFF;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media (min-width: 993px) {
      padding: 0;
      height: 100%;
    }

    &__upper {
      margin-top: 32px;
      padding: $jmt-spacing;
      @media (min-width: 993px) {
        display: flex;
        height: calc(100% - 90px);
        padding: 0;
        margin: 0;
      }

      &__logo {
        display: flex;
        justify-content: space-between;
      }
    }

    &__footer {
      @media(min-width: 993px) {
        height: 90px;
        background: white;
        display: flex;
        justify-content: center;
      }

      &__logo {
        padding-top: 64px;
        @media(min-width: 993px) {
          width: 190px;
          min-width: 190px;
          padding-left: 12px;
          padding-top: 4px;
        }
      }

      &__text {
        @media(max-width: 992px) {
          padding: 0 $jmt-spacing;
        }
        color: black;
        font-size: 12px;
        text-align: justify;
        line-height: 1.3;
        flex-grow: 1;
      }

      &__qr {
        @media(max-width: 992px) {
          display: none;
        }
        padding-right: 32px;
        padding-left: 16px;
        padding-top: 4px;

        > canvas {
          max-width: 76px;
          max-height: 76px;
        }
      }

      .borussia-shanghai__logo {
        margin-left: 8px;
        display: flex;
        svg {
          height: 64px !important;
          margin-right: -8px;
        }
        &__text {
          scale: .8;
          color: black;
          transform: rotate(-5deg);
          margin-top: 8px;
          h1 {
            font-size: 24px !important;
          }
        }
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    .bolder {
      font-weight: 900;
    }

    &__talent-card-wrapper {
      @media (min-width: 993px) {
        max-width: 33%;
        padding: 24px 16px 16px 16px;
        .talentcard-medium {
          scale: .9;
        }
      }
      @media (max-width: 992px) {
        display: flex;
        justify-content: center;
        padding-top: 32px;
      }
    }

    h1 {
      color: var(--ion-color-primary);
      font-size: 48px;
      line-height: .9;
      @media (min-width: 993px) {
        font-size: 32px;
      }
    }

    &__texts {
      @media (min-width: 993px) {
        padding-left: 60px;
        padding-top: 46px;
        padding-right: 16px;
      }
      position: relative;

      h2 {
        text-transform: none;
        font-size: 34px;
        line-height: 1;
        margin-top: $jmt-spacing*6.5;
        margin-bottom: $jmt-spacing*6.5;
        @media (min-width: 993px) {
          max-width: 80%;
        }
      }

      p {
        margin-top: $jmt-spacing*4;
        font-size: 24px;
      }

      &__siegel {
        padding-left: $jmt-spacing * 0;
      }

      &__signature {
        margin-top: $jmt-spacing*4;
        font-size: 18px;
        display: flex;
        flex-direction: column;

        &__date {
          &__date {
            padding-top: 26px;
          }
          &__footer {
            border-top: 1px solid var(--ion-color-secondary);
            font-size: 14px;
            color: black;
            @media (max-width: 992px) {
              width: 200px;
            }
          }
        }

        &__signature {
          margin-top: 32px;
          @media (min-width: 993px) {
            margin-left: 64px;
            margin-top: 0;
          }

          &__svg {
            width: 130px;
            height: 60px;
            margin-bottom: -12px;
            margin-left: 24px;
          }

          &__footer {
            border-top: 1px solid var(--ion-color-secondary);
            font-size: 14px;
            @media (max-width: 992px) {
              width: 280px;
              margin-bottom: 64px;
            }
          }
        }

        @media (min-width: 993px) {
          flex-direction: row;

        }
      }

      &__logo {
        @media (min-width: 993px) {
          margin: 0;
          position: absolute;
          right: $jmt-spacing*2;
          top: $jmt-spacing*3;
          width: 100px;
          height: auto;
        }
        margin-top: -20px;
      }
    }

    &.page-2 {
      @media(max-width: 992px) {
        padding: $jmt-spacing;
      }

      h1 {
        font-size: 64px;
        @media(min-width: 993px) {
          margin-top: 48px;
          padding-left: 32px;
        }
      }
    }

    &__values {
      @media(min-width: 993px) {
        padding: 0 32px 0 32px;
        height: 445px;
        display: flex;
        gap: $jmt-spacing;
      }

      h5 {
        font-size: 18px;
        font-weight: 700;
      }

      &__col {
        flex-grow: 1;
        flex-basis: 0;

        &__block {
          padding: $jmt-spacing 0;
          @media(min-width: 993px) {
            padding-bottom: $jmt-spacing;
          }
          p {
            font-size: 12px;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}
