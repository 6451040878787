.join-jmt-modal {

  &__login-form {

    width: 100%;

    ion-item::part(native) {
      color: #000000;
    }

    input {
      color: #000000 !important;
      --color: #000000;
      -webkit-text-fill-color: #000000;
    }
  }
}
