@import 'src/theme/variables.scss';

.camps-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.admin-camps__title {
    padding-left: $jmt-spacing;

    .camp-card {
        width: 100%
    }
}
