@import '/src/theme/variables';

.notifications {
    .camp-card--small {
      margin-right: $jmt-spacing;
    }

    .offline-votes-card {
      max-width: 210px;
      width: 100%;
  }

}
