@import "/src/theme/constants";
@import "/src/theme/_mixins";
@import "/src/theme/transitions";


.talents-collapsible-wrapper {
  @include desktop() {
    max-width: 50%;
  }

  .talents-collapsible {
    @include standardTransition(transform);

    &--open {
      transform: rotateX(180deg);
    }
  }

  ion-icon {
    font-size: 20px;
    margin-right: $jmt-spacing*2;
  }
}
.camp {
  &__info {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 100%;
    }
    > div {
      padding: $jmt-spacing $jmt-spacing 0;
      width: 100%;
      @include desktop() {
        padding: 0 $jmt-spacing;
        width: 50%;
      }

      h2 {
        margin-top: 0;
      }
    }

    &__content {
      &__participant-info {
        margin-top: $jmt-spacing*3;
        align-items: center;
        text-align: left;
        --padding-start: 0;
        --padding-end: 0;
        white-space: break-spaces;
        ion-icon {
          margin-right: $jmt-spacing;
        }
      }
    }
  }

  &__group-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__participant-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-items: flex-end;
  }

  &__group-card {
    display: block;
    padding: $jmt-spacing !important;
    z-index: 3;

    &__trainer {
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: $jmt-spacing*0.5;
      }
    }
  }

  &__group-label {
    z-index: 3;
    padding: $jmt-spacing;
    max-width: calc(100% - 150px);
  }

  &__group-title {
    z-index: 3;
    padding: $jmt-spacing;
    max-width: calc(100% - 125px);
    min-height: 84px;
  }

  &__add-group {
    margin: 0 $jmt-spacing;

    ion-card-content {
      ion-icon {
        margin-right: $jmt-spacing*8;
        font-size: 64px;
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__group {
    margin: $jmt-spacing;
    position: relative;
    --ion-text-color: var(--ion-color-step-150);
    width: 100%;
    background: white;
    @include desktop() {
      width: calc(50% - #{$jmt-spacing * 2});
    }

    ion-card-title {
      --color: var(--ion-color-step-150) !important;
    }

    &__missing-votes {
        position: absolute;
        right: $jmt-spacing;
        top: $jmt-spacing;
        z-index: 3;
    }
  }

  &__trainer-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .trainer__caption {
      color: var(--ion-color-dark);
      padding-left: 2*$jmt-spacing;
    }
  }
}

.trainer-card {
  cursor: pointer;
  position: relative;
  padding: $jmt-spacing*2;
  @media (prefers-color-scheme: dark) {
    @include background-gradient(var(--ion-color-light), $jmt-color-stripe-dark, 30%);
  }
  @media (prefers-color-scheme: light) {
    @include background-gradient(var(--ion-color-light), $jmt-color-stripe-light, 30%);
  }

  &--director {
    width: 100% !important;
  }
  overflow: hidden;
  margin: $jmt-spacing;
  width: 100%;
  @media (min-width: 630px) {
    width: calc(50% - 20px);
  }

  display: flex;
  align-content: center;
  align-items: center;

  > ion-icon {
    margin-right: $jmt-spacing*2;
  }

  &__button {
    position: absolute;
    right: 0;
  }
}

.participant-card {
  cursor: pointer;
  position: relative;
  padding: $jmt-spacing*2;
  @media (prefers-color-scheme: dark) {
    @include background-gradient(var(--ion-color-light), $jmt-color-stripe-dark, 30%);
  }
  @media (prefers-color-scheme: light) {
    @include background-gradient(var(--ion-color-light), $jmt-color-stripe-light, 30%);
  }

  &--director {
    width: 100% !important;
  }
  overflow: hidden;
  margin: $jmt-spacing;
  width: 100%;
  @media (min-width: 630px) {
    width: calc(50% - 20px);
  }

  display: flex;
  align-content: center;
  align-items: center;

  > ion-icon {
    margin-right: $jmt-spacing*2;
  }

  &__button {
    position: absolute;
    right: 0;
  }
}

.create-group-modal {
  padding: $jmt-spacing*4 $jmt-spacing;
}

.camp-group {
  &__title {
    display: flex;
    align-items: center;
  }
}
