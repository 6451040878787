@import '/src/theme/constants';
@import 'src/theme/mixins';

.participant-card__wrapper {
  width: 100%;
  @media (min-width: $jmt-width-desktop) {
    width: 50%;
    margin: 0;
  }
  justify-content: flex-end;
  display: flex;
  flex-direction: column;

  &--disabled {
    opacity: .5;
  }
}

.participant-card {
  max-height: 65px;
  cursor: pointer;
  position: relative;
  padding: $jmt-spacing*2;
  @media (prefers-color-scheme: dark) {
    @include background-gradient(var(--ion-color-light), $jmt-color-stripe-dark, 30%);
  }
  @media (prefers-color-scheme: light) {
    @include background-gradient(var(--ion-color-light), $jmt-color-stripe-light, 30%);
  }
  overflow: hidden;
  margin: $jmt-spacing;

  display: flex;
  align-content: center;
  align-items: center;

  &--full-width {
    width: 100%;
  }

  ion-icon {
    margin-right: $jmt-spacing*2;
  }

  &__star {
    margin-left: $jmt-spacing;
    margin-right: 0;
    color: $jmt-color-gold;
  }

  &__caption {
    color: var(--ion-color-dark);
    padding-left: 2*$jmt-spacing;
    display: block;
    align-items: center;

    &--select {
      > ion-icon {
        margin-left: $jmt-spacing*2;
      }
    }

    &__group {
      color: var(--ion-color-medium);
    }
  }

  &__img-btn {
    margin-left: -$jmt-spacing*2;
  }

  &__has-image {
    opacity: 0;
  }

  &__talent-image {
    position: absolute;
    opacity: .9;
    height: 100% !important;
    left: -$jmt-spacing;
    clip-path: circle(100% at 0% 50%);

    &--placeholder {
      opacity: .1;
      background: var(--ion-color-secondary);
      width: 69px;
      height: 69px;
    }
  }

  &__talent-id {
    position: absolute;
    opacity: .3;
    left: calc(50% - 40px);
    height: 175% !important;

    &--preview {
      height: unset !important;
    }
  }

}
