@import "/src/theme/transitions.scss";
@import "/src/theme/_mixins.scss";

.app-intro {
  text-align: center;
  padding: 100px $jmt-spacing 0;
  font-size: 30px;
  z-index: 1;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: var(--ion-color-primary);
  @include complexTransition(opacity);
  color: white;


  .text {
    container-type: inline-size;
    font-size: 7cqw;
    @include desktop {
      font-size: 40px;
    }
  }

  > svg {
    position: absolute;
    left: 40px;
    right: 40px;
    width: calc(100vw - 80px);
    top: 50%;
    transform: translateY(-50%);
    @include complexTransition(left, right, width);

    @media (min-width: 600px) {
      left: calc(50% - 250px);
      width: 500px;
    }
  }

  &.effect {
    opacity: 0;
    > svg {
      left: -150px;
      right: -150px;
      width: calc(100vw + 300px);
    }
  }

}
