@import "/src/theme/variables.scss";

.camera {
  &__example {
    max-width: 100%;
    width: 400px;
    padding: $jmt-spacing;

    &__image {
      margin-top: 2*$jmt-spacing;
    }
  }

  &__buttons {
    .form-upload__item {

      --inner-padding: 0;
      --inner-padding-start: 0;
      --inner-padding-end: 0;

      &__label {
        width: unset;
      }

      ion-button {
        width: 100%;
        text-align: center;
      }
    }
  }
}
