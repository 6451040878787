@import '/src/theme/constants.scss';

.login {
  max-width: 350px;
  margin: 0 auto;

  &__logo {
    margin: 8*$jmt-spacing 0 0;
    display: flex;
    height: auto;
    justify-content: center;
    background-size: contain;

    @media (prefers-color-scheme: light) {
      svg path {
        fill: var(--ion-color-primary)!important;
      }
    }
  }

  &__form {

    &__icon {
      ion-icon {
        height: 90px;
        width: 90px;
      }
    }
  }

  &__button {
    margin-top: 2*$jmt-spacing;
    width: 100%;
    display: flex;
    flex-grow: 1;
    > ion-button {
      flex-grow: 1;
      width: 100%;
    }
  }
}
