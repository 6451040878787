@import 'src/theme/constants';
@mixin sp-layout {
  @media screen and (max-width: 750px) {
    @content;
  }
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  transform: rotate(-45deg);
}

.star {
  $star-count: 10;
  --star-color: #888888;
  --star-tail-length: 6em;
  --star-tail-height: 2px;
  --star-width: calc(var(--star-tail-length) / 6);
  --fall-duration: 6s;
  --tail-fade-duration: var(--fall-duration);

  position: absolute;
  top: var(--top-offset);
  left: 0;
  width: var(--star-tail-length);
  height: var(--star-tail-height);
  color: var(--star-color);
  background: linear-gradient(45deg, currentColor, transparent);
  border-radius: 50%;
  filter: drop-shadow(0 0 6px currentColor);
  transform: translate3d(104em, 0, 0);
  animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;

  @include sp-layout {
    // For mobile performance, tail-fade animation will be removed QAQ
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
  }

  @for $i from 1 through $star-count {
    &:nth-child(#{$i}) {
      --star-tail-length: calc(#{random_range(500em, 750em)} / 100);
      --top-offset: calc(#{random_range(0vh, 10000vh)} / 100);
      --fall-duration: calc(#{random_range(6000, 12000s)} / 1000);
      --fall-delay: calc(#{random_range(0, 10000s)} / 1000);
    }
  }

  &::before, &::after {
    position: absolute;
    content: '';
    top: 0;
    left: calc(var(--star-width) / -2);
    width: var(--star-width);
    height: 100%;
    background: linear-gradient(45deg, transparent, currentColor, transparent);
    border-radius: inherit;
    animation: blink 2s linear infinite;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@keyframes fall {
  to {
    transform: translate3d(-30em, 0, 0);
  }
}

@keyframes tail-fade {
  0%, 50% {
    width: var(--star-tail-length);
    opacity: 1;
  }

  70%, 80% {
    width: 0;
    opacity: 0.4;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 0.6;
  }
}

.jmt-certificate-wrapper {
  position: relative;
  overflow: hidden;
  padding: $jmt-spacing;
  //--accent-color: rgb(0 90 170);
  //--accent-color: rgb(0 50 100);
  background: radial-gradient(ellipse at bottom, #64063e 0%, #0c0d13 100%);
  @media(min-width: 993px) {
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.5);
    height: 640px;
  }
  position: relative;
  margin-bottom: $jmt-spacing * 2;

  @media (max-width: 992px) {
    width: 360px;
    margin: 0 auto;
  }

  .certificate {
    height: 100%;
    color: white;
    background-color: var(--accent-color);
    overflow: hidden;
    @media (min-width: 993px) {
      //border: 2px solid white;
      padding: $jmt-spacing $jmt-spacing $jmt-spacing;
      height: 624px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    .bolder {
      font-weight: 900;
    }

    &__main {
      &__talent-card-wrapper {
        @media (min-width: 993px) {
          max-width: 33%;
          padding: 24px 16px 16px 6px;
          .talentcard-medium {
            scale: .9;
          }
        }
        @media (max-width: 992px) {
          display: flex;
          justify-content: center;
        }
      }

      &__content {
        &__header {
          &__title {
            &__headline {

              @media (max-width: 992px) {
                display: none;
              }

              margin-top: 16px;
              height: 90px;
              flex-direction: column;
              padding-right: 16px;

              h3 {
                font-size: 24px;
              }

              h1 {
                margin-top: 0;
                font-size: 42px;
              }

              &--mobile {
                @media (min-width: 993px) {
                  display: none;
                }

                display: flex;

                text-align: left;

                h3 {
                  margin-top: 32px;
                  min-width: 300px;
                  font-size: 20px;
                }

                h1 {
                  margin-bottom: 32px;
                  font-size: 29px;
                  min-width: 300px;
                }
              }
            }

            &__name {
              @media (min-width: 993px) {
                height: 118px;
                align-content: flex-end;
              }
            }

            .name {
              font-weight: bolder;
              font-size: 36px;
              margin-top: 48px;
              @media (max-width: 992px) {
                min-width: 300px;
                margin-top: 0;
              }
            }
          }

          &__name {
            height: 87px;
            vertical-align: bottom;
          }

          &__img-wrapper {
            max-width: 100px;

            @media (max-width: 992px) {
              margin-top: 48px;
              flex-direction: row !important;
              justify-content: center;
              max-width: 100%;
              img {
                width: 100px;
              }

              > canvas {
                margin-left: 32px;
                margin-top: 0 !important;
                max-width: 110px !important;
                max-height: 110px !important;
                margin-right: 16px;
              }
            }

            > *:not(:last-child) {
              margin-bottom: $jmt-spacing;
            }

            > canvas {
              margin-top: 16px;
              max-width: 90px;
              max-height: 90px;
            }
          }
        }

        &__text {

        }

        &__signature {
          margin-top: 48px;
          font-size: 20px;

          &__name {
            font-weight: bold;
          }
        }

      }

      &__signature {
        align-items: center;
        margin-top: 52px;
        margin-left: 50%;
        transform: translateX(-25%);
        rotate: 19deg;

        &__sign {
          width: 130px;
          height: 80px;
        }

        &__siegel {
          max-width: 80px;
          height: auto;
        }
      }
    }

    &__footer {
      position: absolute;
      bottom: 20px;
      padding-right: 16px;
      text-align: justify;
      padding-left: 8px;
      line-height: 1;

      @media (max-width: 992px) {
        position: relative;
        margin-top: 48px;
      }

      ion-text {
        font-size: 10px;
        text-align: justify;
      }

      &__img {
        width: auto;
        height: 45px;
        max-height: 45px;
        margin-top: 8px;
      }

      &__logo {
        height: 45px;
        margin-top: 9px;
      }
    }

    &__page-2 {
      align-items: start;
    }

    &__id {
      max-width: 200px;

      .talentcard-medium {
        scale: .6;
        left: -56px;
      }
    }

    &__texts {
      //margin: 16px;
      h1 {
        font-weight: bold;
        margin: 8px 16px 16px 12px;
        @media(min-width: 993px) {
          margin: 16px 4px 16px 4px;
        }
      }

      @media(min-width: 993px) {
        padding-left: 8px;
        padding-right: 24px;
      }

      &__block {
        @media(max-width: 992px) {
          min-width: 300px;
          margin-right: 0;
        }

        text-align: justify;
        margin-bottom: $jmt-spacing*2;

        h5 {
          font-weight: bolder;
          margin-left: 8px;
          margin-right: 8px;
          @media(min-width: 993px) {
            margin-left: 16px;
          }
        }

        &__description {
          ion-text {
            font-size: 12px !important;
          }

          line-height: .8;
          margin-left: 8px;
          margin-right: 8px;
          @media(min-width: 993px) {
            margin-left: 16px;
          }
        }
      }
    }

    .hide-small {
      @media (max-width: 992px) {
        display: none;
      }
    }

    .hide-big {
      @media (min-width: 993px) {
        display: none;
      }
    }
  }
}
