@import 'src/theme/constants';

.jmt-button {

    &.ios:not(.button-clear) {
        --border-radius: 4px;
        height: 2.7em;
    }

    &__label {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        line-height: 1.2;
    }

    &--has-content {
        .jmt-button__loading-spinner,
        ion-icon {
            margin-inline-end: $jmt-spacing;
            font-size: 16px;
            margin-bottom: 2px;
        }
    }

    &--round {
        --border-radius: 50%;
        width: 55px;
        height: 55px;
    }

    &__loading-spinner {
        height: 1em;
        width: 1em;
        margin-inline: -0.3em 0.3em;
        --color: currentColor !important;
    }
}
