@import "/src/theme/variables.scss";
@import "/src/theme/_mixins.scss";

.dashboard {

  &__talents {
    padding: 0;
    margin: -$jmt-spacing*1.5;
    margin-top: $jmt-spacing*2;
    justify-content: space-between;

    &__item {
      padding: 0;
      margin: 0;
      min-width: 100%;
      @include desktop() {
        min-width: 50%;
        max-width: 50%;
      }

      .participant-card__wrapper {
        width: 100%;
      }
    }
  }

}
